import React from 'react'

class ListItem extends React.Component {
  render() {
    return <div className="list-item">
      <div className="row">
        <div className="col-md-12 d-flex align-items-center">
          <h3>Event Titel</h3>
          <span className="time-indicator">10 Seconds</span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="count-badge">5</div>
          <span className="description">Measurement Value – Measurement Value</span>
        </div>
      </div>
    </div>
  }
}

export default ListItem
